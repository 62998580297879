import React, { useRef } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Divider from '@mui/material/Divider';
import { styled } from '@mui/material/styles';
import HotelsComponent from '../Hotels/HotelsComponent';
import Countdown from '../Countdown/CountdownTimerComponent';
import PhotoComponent from '../PhotoComponent/PhotoComponent';
import Logo from '../../Assets/logo.png'


import './MainPageComponent.css'
import { HaciendaVistahermosa, Excelaris } from '../Hotels/HotelInformation';
import DateComponent from '../DateComponent/DateComponent';
import GiftsComponent from '../Gifts/GiftsComponent';
import VenueComponent from '../Venue/VenueComponent';
// import RSVPComponent from '../RSVPComponent/RSVPComponent';
import DressCodeComponent from '../DressCodeComponent/DressCodeComponent';
import { Grid } from '@mui/material';

function MainPageComponent() {
  const DateRef = useRef<null | HTMLDivElement>(null);
  const VenueRef = useRef<null | HTMLDivElement>(null);
  const AccomodationRef = useRef<null | HTMLDivElement>(null);
  const GiftsRef = useRef<null | HTMLDivElement>(null);
  const DressCodeRef = useRef<null | HTMLDivElement>(null);
  const RSVPRef = useRef<null | HTMLDivElement>(null);
  const FAQRef = useRef<null | HTMLDivElement>(null);

  let lang: string;

  if (window.navigator.languages.length > 0) {
    lang = window.navigator.languages[0];
  }
  else {
    lang = window.navigator.language;
  }


  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    switch (newValue) {
      case 0:
        DateRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 1:
        VenueRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 2:
        GiftsRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 3:
        AccomodationRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 4:
        DressCodeRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 5:
        RSVPRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case 6:
        FAQRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
    }
    console.log(newValue);
  };

  return (
    <div ref={DateRef}>
      <div className='PhotoContainer'>
        <PhotoComponent />
      </div>
      <div className='MainContentContainer'>
        <DateComponent />
        <Countdown />
        <div className='MenuDiv'>
          <Tabs
            variant="scrollable"
            onChange={handleChange} value={false}>
            <Tab label={<img src={Logo} className="LogoTab" alt='Sofía and Iker Logo' />} value={0} />
            <StyledTab label={lang.includes("es") ? "Itinerario" : "Itinerary"} value={1} />
            <Divider orientation="vertical" variant='middle' flexItem />
            <StyledTab label={lang.includes("es") ? "Vestimenta" : "Dresscode"} value={4} />
            <Divider orientation="vertical" variant='middle' flexItem />
            <StyledTab label={lang.includes("es") ? "Hospedaje" : "Accomodation"} value={3} />
            <Divider orientation="vertical" variant='middle' flexItem />
            <StyledTab label={lang.includes("es") ? "Regalos" : "Gifts"} value={2} />
            <Divider orientation="vertical" variant='middle' flexItem />
            {/* <StyledTab label={lang.includes("es") ? "RSVP" : "RSVP"} value={5} /> */}
            <StyledTab label={lang.includes("es") ? "FAQ" : "FAQ"} value={6} />
          </Tabs>
        </div>

        {/* The MarginDiv class just adds space for the menu to be */}
        <div ref={VenueRef} className="MarginDiv" />
        <VenueComponent />
        <Divider style={{ marginBottom: 15, marginTop: 15 }} variant='middle' />

        {/* The MarginDiv class just adds space for the menu to be */}
        <div ref={DressCodeRef} className="MarginDiv" />
        <DressCodeComponent />
        <Divider style={{ marginBottom: 15, marginTop: 15 }} variant='middle' />

        {/* The MarginDiv class just adds space for the menu to be */}
        <div ref={AccomodationRef} className="MarginDiv" />
        <HotelsComponent {...HaciendaVistahermosa} />
        {<HotelsComponent {...Excelaris} />}
        <Divider style={{ marginBottom: 15, marginTop: 15 }} variant='middle' />

        {/* The MarginDiv class just adds space for the menu to be */}
        <div ref={GiftsRef} className="MarginDiv" />
        <GiftsComponent />
        <Divider style={{ marginTop: 15 }} variant='middle' />

        <div ref={FAQRef} className="MarginDiv" />
        <ImportantInformation />
        <Divider style={{ marginTop: 15 }} variant='middle' />
        {/* The MarginDiv class just adds space for the menu to be */}
        {/* <div ref={RSVPRef} className="MarginDiv" />
        <RSVPComponent />
        <Divider style={{ marginTop: 15 }} variant='middle' /> */}


      </div>
    </div >

  );
}

function ImportantInformation(): JSX.Element {

  let lang: string;

  if (window.navigator.languages.length > 0) {
    lang = window.navigator.languages[0];
  }
  else {
    lang = window.navigator.language;
  }

  return (
    lang.includes("es") ?
      <div>
        <Grid container spacing={1} justifyContent="center" alignItems="stretch">
          <Grid md={12} lg={6} className='gridStyle'>

            <p className='Title'>Información Importante</p>
            <div className='Description'>

              <b>Transporte de la Hacienda Vista Hermosa al Hotel Excelaris</b><br />
              -	Sábado, a partir de las 20:00 horas. <br />
              <br />
              <b>Recomendaciones de salón de belleza</b><br />
              Contactar para agendar servicio en Hacienda Vista Hermosa:<br />
              -	Esther Benitez: 777 254 35 26<br />
              <br />
              <b>Clima</b><br />
              La Temperatura máxima en febrero es de 32° y la mínima de 15°<br />
              <br />
              <b>¿Puedo pasar el día en la Hacienda?</b><br />
              Si no te estás hospedando en la Hacienda Vista Hermosa podrás pasar el día diciendo qué eres invitado de la boda de Ana Paula y Manuel<br />

            </div>
          </Grid>
        </Grid>
      </div>
      :
      <div>
        <Grid container spacing={1} justifyContent="center" alignItems="stretch">
          <Grid md={12} lg={6} className='gridStyle'>

            <p className='Title'>Important Information</p>
            <div className='Description'>

              <b>Transportation from Hacienda Vista Hermosa to Hotel Excelaris</b><br />
              -	Saturday, starting at 8:00 p.m. <br />
              <br />
              <b>Beauty Salon Recommendations:</b><br />
              Contact to schedule service at Hacienda Vista Hermosa:<br />
              -	Esther Benitez: 777 254 35 26<br />
              <br />
              <b>Weather</b><br />
              The maximum temperature in February is 32°C and the minimum is 15°C.<br />
              <br />
              <b>Can I spend the day at the Hacienda?</b><br />
              If you are not staying at Hacienda Vista Hermosa, you can spend the day by saying you are a guest of Ana Paula and Manuel’s wedding.<br />

            </div>
          </Grid>
        </Grid>
      </div >
  );

}

interface StyledTabProps {
  label: string;
  value: number;
}

const StyledTab = styled((props: StyledTabProps) => (
  <Tab {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  fontFamily: 'Aboreto',
  fontSize: "3vh",
  maxWidth: 'none',
}));

export default MainPageComponent;