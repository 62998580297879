import React from 'react';

import Grid from '@mui/material/Grid';

import Waze from '../../Assets/waze.svg';
import GMaps from '../../Assets/gmaps.svg';
import HotelAddressComponent from './HotelAddressComponent';
import Hotel from './Interfaces';

import './Hotels.css'

class HotelsComponent extends React.Component {
    public lang: string = '';
    private hotel?: Hotel = undefined;

    public constructor(props: Hotel) {
        super(props);

        this.hotel = props;
        console.log(props);
    }

    public render(): React.ReactNode {


        return (
            <div >
                <Grid container spacing={1} justifyContent="center" alignItems="stretch">
                    <Grid md={12} lg={6} className='gridStyle'>
                        <div className='detailsText'>
                            <div className="hotelName">
                                {this.hotel?.name}
                            </div>
                            <div className='detailsAddress'>

                            </div>
                            <a className='link' href={this.hotel?.website}
                                target="_blank" rel="noreferrer">{this.hotel?.website}</a>
                            <div>
                                <HotelAddressComponent {...this.hotel} />
                            </div>
                        </div>

                    </Grid>
                    <Grid md={12} lg={6} >
                        <div className='gridStyle'>

                            <img className='map' src={this.hotel?.mapAsset} alt='Map of the venue' />

                            <div>
                                <a href={this.hotel?.mapURLWaze} target="_blank" rel="noreferrer"><img alt='Waze link' className='mapButtons' src={Waze} /></a>
                                <a href={this.hotel?.mapURLGM} target="_blank" rel="noreferrer"><img alt='Google Maps link' className='mapButtons' src={GMaps} /></a>
                            </div>
                        </div>

                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default HotelsComponent;