import React from 'react';

import './SaveTheDateComponent.css';

import Divider from '@mui/material/Divider';
import Countdown from '../Countdown/CountdownTimerComponent';



import { SaTDaVenue } from '../Venue/VenueComponent';
import PhotoComponent from '../PhotoComponent/PhotoComponent';

/**
 * Component that shows the save the date
 */
class SaveTheDateDetailsComponent extends React.Component {
    lang: string | undefined;


    public constructor(props: any) {
        super(props);

        if (window.navigator.languages.length > 0) {
            this.lang = window.navigator.languages[0];
        }
        else {
            this.lang = window.navigator.language;
        }
    }

    /**
     * 
     * @returns A React.ReactNode object with the html of the component
     */
    public render(): React.ReactNode {
        return (
            <div className='saveTheDate'>
                <PhotoComponent />
                <p className='saveTheDateTitle'>Save the Date</p>
                <Countdown />
                <Divider />
                <SaTDaVenue />
                <Divider />
                <a href="/" className='detailsLink'>
                    {
                        this.lang?.includes("es") ?
                            <p> Alojamiento, Código de Vestimenta, Regalos y más...</p>
                            : <p>Lodging, Dress Code, Gift Registries and more...</p>
                    }
                </a>
            </div>
        );
    }
}

export default SaveTheDateDetailsComponent;
