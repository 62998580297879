import { Grid } from "@mui/material";
import './DressCodeComponent.css';
// import { CarouselProvider, Slide, Slider } from "pure-react-carousel";
// import Pinterest from '../../Assets/pinterest_logo.png'

// // import Dress1 from '../../Assets/DressCode/Dress1.jpg'
// import Dress2 from '../../Assets/DressCode/Dress2.jpg'
// import Dress3 from '../../Assets/DressCode/Dress3.jpg'
// import Dress4 from '../../Assets/DressCode/Dress4.jpg'
// import Dress5 from '../../Assets/DressCode/Dress5.jpg'

// // import Guayabera1 from '../../Assets/DressCode/Guayabera1.png'
// import Guayabera2 from '../../Assets/DressCode/Guayabera2.jpg'
// import Guayabera3 from '../../Assets/DressCode/Guayabera3.jpg'
// import Guayabera4 from '../../Assets/DressCode/Guayabera4.jpg'
// import Guayabera5 from '../../Assets/DressCode/Guayabera5.jpg'

// function DressCodeCarouselCard(props: { img: string }): JSX.Element {
//     return (
//         <div>
//             <img className='photos' alt="Dress code example" src={props.img} />
//         </div >
//     )
// }

export default function DressCodeComponent(props: any): JSX.Element {
    let lang: string = '';

    if (window.navigator.languages.length > 0) {
        lang = window.navigator.languages[0];
    }
    else {
        lang = window.navigator.language;
    }

    return (
        <div className="DressCodeComponent">
            <p className="DressCodeTitle">{lang.includes("es") ? "Código de Vestimenta" : "Dress Code"}</p>
            <Grid container>
                <Grid xs={12} sm={6}>
                    <p className="DressCodeDescription">{lang.includes('es') ? "Guayabera Formal" : "Formal Linen Shirt"}</p>
                    {/* <CarouselProvider
                        naturalSlideWidth={4}
                        naturalSlideHeight={3}
                        totalSlides={4}
                        isPlaying={true}
                        infinite={true}
                    >
                        <Slider>
                            <Slide index={1}><DressCodeCarouselCard img={Guayabera2} /></Slide>
                            <Slide index={2}><DressCodeCarouselCard img={Guayabera3} /></Slide>
                            <Slide index={3}><DressCodeCarouselCard img={Guayabera4} /></Slide>
                            <Slide index={4}><DressCodeCarouselCard img={Guayabera5} /></Slide>
                        </Slider>
                    </CarouselProvider> */}
                    {/* <a href="https://pin.it/6u55lrv" target="_blank" rel="noreferrer noopener"><img className="PinterestLogo" alt='Pinterest link' src={Pinterest} /></a> */}
                </Grid>
                <Grid xs={12} sm={6}>
                    <p className="DressCodeDescription">{lang.includes('es') ? "Vestido Formal" : "Formal Dress"}</p>
                    {/* <CarouselProvider
                        naturalSlideWidth={4}
                        naturalSlideHeight={3}
                        totalSlides={4}
                        isPlaying={true}
                        infinite={true}
                    >
                        <Slider>
                            <Slide index={1}><DressCodeCarouselCard img={Dress2} /></Slide>
                            <Slide index={2}><DressCodeCarouselCard img={Dress3} /></Slide>
                            <Slide index={3}><DressCodeCarouselCard img={Dress4} /></Slide>
                            <Slide index={4}><DressCodeCarouselCard img={Dress5} /></Slide>
                        </Slider>
                    </CarouselProvider> */}
                    {/* <a href="https://pin.it/1AfbnGn" target="_blank" rel="noreferrer noopener"><img className="PinterestLogo" alt='Pinterest link' src={Pinterest} /></a> */}
                </Grid>
            </Grid>
        </div>
    )
}