import React from 'react';
import Hotel from './Interfaces';

import './Hotels.css'

class HotelAddressComponent extends React.Component {
    private lang: string = '';

    private hotel?: Hotel;

    public constructor(props: Hotel) {
        super(props);
        if (window.navigator.languages.length > 0) {
            this.lang = window.navigator.languages[0];
        }
        else {
            this.lang = window.navigator.language;
        }

        console.log(props);
        this.hotel = props;
    }

    public render(): React.ReactNode {
        if (this.lang.toLowerCase().includes("es")) {
            return (
                <div className='reservationsInstructions'>
                    <br />
                    Las reservaciones deberán ser realizadas por alguno de los siguientes medios:<br />
                    Teléfono: <a className='reservationsLinks' href={this.hotel?.telefoneHREF}>{this.hotel?.telephone}</a><br />
                    Correo: <a className='reservationsLinks' href={'mailto:' + this.hotel?.email}>{this.hotel?.email}</a><br />
                    <br />
                    Mencionar el código de reservación:<br /><b>'Ana Paula y Manuel / 8 de febrero de 2025'</b>
                </div>
            );
        }
        else {
            return (
                <div className='reservationsInstructions'>
                    <br />
                    Room reservations should be done using only the following methods:<br />
                    Phone: <a className='reservationsLinks' href={this.hotel?.telefoneHREF}>{this.hotel?.telephone}</a><br />
                    Email: <a className='reservationsLinks' href={'mailto:' + this.hotel?.email}>{this.hotel?.email}</a><br />
                    <br />
                    Please mention the reservation code to the operator:<br /><b>'Ana Paula y Manuel / 8 de febrero de 2025'</b>
                </div>
            );
        }
    }
}

export default HotelAddressComponent;
