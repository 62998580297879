import Grid from '@mui/material/Unstable_Grid2';
import GiftComponent from "./GiftComponent";
import { SIBank, SIUniko } from "./Gifts";

import './GiftsComponent.css';

function GiftsComponent() {
    let lang: string;

    if (window.navigator.languages.length > 0) {
        lang = window.navigator.languages[0];
    }
    else {
        lang = window.navigator.language;
    }

    return (
        <div className="gridStyle">
            <div className='giftIntro'>
                {
                    lang.includes("es") ?
                        <p>Muchas gracias por acompañarnos. Si quieres tener un detalle estas son las opciones que tenemos: </p>
                        : <p>Thank you for being with us. If you wish to to support us we have these options: </p>
                }
            </div>


            <Grid container spacing={5} display="flex" justifyContent="center" alignItems="stretch">
                <Grid md={12} lg={4} className='gridStyle'>
                    <GiftComponent {...SIUniko} />
                </Grid>
                <Grid md={12} lg={4} className='gridStyle'>
                    <GiftComponent {...SIBank} />
                </Grid>
            </Grid>
            <div>

            </div>

        </div>
    );
}

export default GiftsComponent;