interface WeddingInformation {
    date: Date;
    weddingName: string;
}

export default WeddingInformation;

export const WeddingInfo: WeddingInformation = {
    date: new Date('2025-02-08T12:30:00.000-06:00'),
    weddingName: 'Ana Paula & Manuel'
};