import './DateComponent.css'

function DateComponent(): JSX.Element {
    let lang: string;

    if (window.navigator.languages.length > 0) {
        lang = window.navigator.languages[0];
    }
    else {
        lang = window.navigator.language;
    }


    return (
        <div className='detailsDate'>
            {lang.toLowerCase().includes("es") ? "8 de Febrero de 2025" : "February 8th, 2025"}<br />
            12:00 p.m.
        </div>
    );
}

export default DateComponent;