import { GiftsOption } from "./Interfaces"
import Uniko from '../../Assets/uniko.png'

export const SIUniko: GiftsOption = {
    type: 'retail',
    url: 'https://evento.uniko.co/anapauymanuel/',
    identifier: 'anapauymanuel',
    icon: Uniko,
    name: 'Uniko',
    description: '',
}

export const SIBank: GiftsOption = {
    type: 'bank',
    identifier: '0211 8004 0643 8435 70',
    name: 'HSBC México',
    description: 'Regalo de bodas',
    accountHolderName: 'Manuel Iturbide Herrera',
    SWIFTNumber: ''
}
