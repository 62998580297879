import Hotel from './Interfaces'

import MapExcelaris from '../../Assets/SaveTheDate/map_excelaris.jpg';
import MapHV from '../../Assets/SaveTheDate/map.jpg';

export const HaciendaVistahermosa: Hotel = {
    name: "Hacienda Vistahermosa",
    address: "Km 7 Carretera Alpuyeca Tequesquitengo\nSan José Vista Hermosa\nPuente de Ixtla, Morelos. México.",
    telephone: "+52 734 342 9040",
    telefoneHREF: "tel:+527343429040",
    email: "reservaciones@haciendavistahermosa.com.mx",
    website: "https://www.haciendavistahermosa.com.mx",
    mapAsset: MapHV,
    mapURLGM: "https://goo.gl/maps/vceqrnt8Hp8Zg3398",
    mapURLWaze: "https://ul.waze.com/ul?preview_venue_id=170852539.1708721994.1444469&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
}

export const Excelaris: Hotel = {
    name: "Excelaris Grand Resort",
    address: "2 Blvd. Tequesquitengo S/N Frente a Glorieta Las Alas.\nSan José Vista Hermosa\nTequesquitengo, Morelos. México.",
    telephone: "+52 734 345 7500",
    telefoneHREF: "tel:+527343457500",
    email: "ventas.excelaris@gmail.com",
    website: "https://www.excelarisgrandresort.com",
    mapAsset: MapExcelaris,
    mapURLGM: "https://goo.gl/maps/hnw26ak7Ca5b8zX57",
    mapURLWaze: "https://waze.com/ul/h9g36j19mu"
}