import { useState, useEffect } from "react";

import './CountdownTimerComponent.css';
import { WeddingInfo } from "../../Common";

export default function Countdown(props: any) {

    let now: Date = new Date();


    let lang: string = '';

    if (window.navigator.languages.length > 0) {
        lang = window.navigator.languages[0];
    }
    else {
        lang = window.navigator.language;
    }


    let timeRemaining: number = (WeddingInfo.date.getTime() - now.getTime()) / 1000;
    timeRemaining = Math.floor(timeRemaining);
    let remaingMins = Math.floor(timeRemaining / 60);
    let remainingHours = Math.floor(remaingMins / 60);

    const { startingSeconds = timeRemaining } = props;
    const [secs, setSeconds] = useState(startingSeconds % 60);
    const [mins, setMinutes] = useState(remaingMins % 60);
    const [hours, setHours] = useState(remainingHours % 24);
    const [days, setDays] = useState(Math.floor(remainingHours / 24));


    useEffect(() => {
        let timeRemaining = startingSeconds;
        let sampleInterval = setInterval(() => {

            timeRemaining -= 1;
            setSeconds(timeRemaining % 60);

            let remaingMins = Math.floor(timeRemaining / 60);
            setMinutes((remaingMins % 60));

            let remainingHours = Math.floor(remaingMins / 60);
            setHours(remainingHours % 24);

            setDays(Math.floor(remainingHours / 24));

        }, 1000);
        return () => {
            clearInterval(sampleInterval);
        };
    });

    return (
        <div className="countdownTimer">
            <div className="values">
                <div className="numbers">{days < 10 ? `0${days}` : days}</div>
                {lang.toLowerCase().includes("es") ? "Días" : "Days"}
            </div>
            <div className="values">
                <div className="numbers">{hours < 10 ? `0${hours}` : hours}</div>
                {lang.toLowerCase().includes("es") ? "Horas" : "Hours"}
            </div>
            <div className="values">
                <div className="numbers">{mins < 10 ? `0${mins}` : mins}</div>
                Mins.
            </div>
            <div className="values">
                <div className="numbers">{secs < 10 ? `0${secs}` : secs}</div>
                {lang.toLowerCase().includes("es") ? "Seg." : "Sec."}
            </div>
        </div>
    );
}