import React from 'react';

import SaveTheDateDetailsComponent from './SaveTheDateDetailsComponent'

import './SaveTheDateComponent.css'


/**
 * Component that shows the save the date
 */
class SaveTheDateComponent extends React.Component {
    private ImgToUse: string = '';
    private lang: string = '';
    private SaveTheDateProps = {
        lang: "",
    }

    public constructor(props: any) {
        super(props);


    }

    /**
     * 
     * @returns A React.ReactNode object with the html of the component
     */
    public render(): React.ReactNode {
        return (
            <div >

                <SaveTheDateDetailsComponent {...this.SaveTheDateProps} />

            </div>
        );
    }
}

export default SaveTheDateComponent;
