import { GiftsOption } from './Interfaces';
import PaymentsIcon from '@mui/icons-material/Payments';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import './GiftsComponent.css'
import IconButton from '@mui/material/IconButton';

function openURL(url?: string) {
    if (url !== undefined) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }
}

function GiftComponent(props: GiftsOption): JSX.Element {

    let lang: string;

    if (window.navigator.languages.length > 0) {
        lang = window.navigator.languages[0];
    }
    else {
        lang = window.navigator.language;
    }

    return (
        <div className='GiftComponentCard'>
            <div>
                {props.type === 'bank' ? <PaymentsIcon className='logo' style={{ fontSize: "8vh", color: '#8a8a8a' }} /> : <img className='logo' style={{ height: "8vh" }} src={props.icon} alt='Logo of gifts' />}
            </div>
            <div>
            </div>
            {props.type !== 'bank' ?
                <div>
                    <p style={{ fontFamily: 'Aboreto', fontSize: "3.5vh" }}>{props.name}</p>
                    <p >
                        {lang.includes("es") ? "Número de Mesa de Regalos: " : "Gift Registry: "}
                        {props.identifier}
                    </p>
                    <p>{props.description}</p>
                    <IconButton onClick={() => { openURL(props.url) }} style={{ fontSize: "2vh", fontFamily: 'Montserrat' }}>
                        {lang.includes("es") ? "Ver mesa " : "See registry "}
                        <ArrowForwardIcon />
                    </IconButton>
                </div> :
                <div >
                    <p style={{ fontFamily: 'Aboreto', fontSize: "3.5vh" }}>{props.name}</p>
                    <p>
                        {lang.includes("es") ? "CLABE: " : "Account No: "}
                        {props.identifier}
                    </p>
                    <p>
                        {lang.includes("es") ? "Nombre: " : "Name: "}
                        {props.accountHolderName}
                    </p>
                    <p>
                        {lang.includes("es") ? "Concepto: " : "Description: "}
                        {props.description}
                    </p>
                    <p>
                        {lang.includes("es") ? "Porfavor incluye tu nombre en el concepto."
                            : "Please include your name in the transfer description."}
                    </p>

                </div>
            }

        </div >
    );
}

export default GiftComponent;