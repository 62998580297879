import React from 'react';
import './App.css';

import { BrowserRouter, Route, Routes, Outlet, Navigate } from "react-router-dom";

import SaveTheDateComponent from "./Components/SaveTheDate/SaveTheDateComponent"
import MainPageComponent from './Components/MainPageComponent/MainPageComponent';


class App extends React.Component {

    public render(): React.ReactNode {

        return (
            <div className="App">
                <BrowserRouter>
                    <Routes>
                        {/* <Route path="/" element={<MainPageComponent />} /> */}
                        <Route path="/" element={<Navigate to={"/Home"} />} />
                        <Route path="/Home" element={<MainPageComponent />} />
                        <Route path="/SaveTheDate" element={<SaveTheDateComponent />} />
                    </Routes>

                    <Outlet />
                </BrowserRouter>
                <div className='appFooter'>
                    Made with ❤️ by Íker
                </div>
            </div>
        );
    }
}

export default App;
